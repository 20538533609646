import { useEffect, useState } from "react";
import visaLogo from "../Assets/visa-logo.png";
import Profile from "../Components/Profile";
import ImageModal from "../Components/ImageModal";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { logOutUser } from "../store/authSlices";
import FormModal from "../Components/FormModal";
import Loading from "../Components/Loading";
export default function OtherUserProfile() {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showImageModal, setImageModal] = useState(false);
  const [education, setEducation] = useState();
  const [profession, setProfession] = useState();
  const [document, setDocument] = useState();
  const param = useParams();
  const { id } = param;
  const [data, setData] = useState();
  const [degree, setDegree] = useState("");
  const state = useSelector((state) => {
    return state.user;
  });
  const dispatch = useDispatch();

  const [questions, setQuestions] = useState([]);

  const url =
    process.env.REACT_APP_API_URL + `api/user/get/other/profile/${id}`;
  const professionUrl =
    process.env.REACT_APP_API_URL + `api/otherUserProfessionDocuments/${id}`;
  const educationUrl =
    process.env.REACT_APP_API_URL + `api/otherUserEducationDocuments/${id}`;
  const documentUrl = process.env.REACT_APP_API_URL + `api/get/document/${id}`;

  useEffect(() => {
    const getUser = async () => {
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        })
        .then((response) => {
          setData(response?.data?.data);
          setQuestions(response?.data?.questions);
        })
        .catch((err) => {
          if (err?.message === "Network Error" && state?.login == true) {
            toast.error("Check your internet Connection");
          }
        });
    };

    getUser();
  }, []);
  useEffect(() => {
    const getEducation = async () => {
      axios
        .get(educationUrl, {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        })
        .then((response) => {
          setEducation(response.data.data);
        })
        .catch((err) => {
          if (err?.message === "Network Error" && state?.login == true) {
            toast.error("Check your internet Connection");
          }
        });
    };

    getEducation();
  }, []);
  useEffect(() => {
    const getProfession = async () => {
      axios
        .get(documentUrl, {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        })
        .then((response) => {
          setDocument(response.data.data);
        })
        .catch((err) => {
          if (err?.response?.data?.message == "Token has expired") {
            toast.error("Your token has been expired again sign in");
            dispatch(logOutUser());
            return;
          }
          if (err?.message === "Network Error" && state?.login == true) {
            toast.error("Check your internet Connection");
          }
        });
    };

    getProfession();
  }, []);
  useEffect(() => {
    const getDocument = async () => {
      axios
        .get(professionUrl, {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        })
        .then((response) => {
          setProfession(response.data.data);
        })
        .catch((err) => {
          if (err?.response?.data?.message == "Token has expired") {
            toast.error("Your token has been expired again sign in");
            dispatch(logOutUser());
            return;
          }
          if (err?.message === "Network Error" && state?.login == true) {
            toast.error("Check your internet Connection");
          }
        });
    };

    getDocument();
  }, []);

  const compatibility = () => {
    const total = 5;
    let counter = 0;
    if (state?.user?.religion == data?.religion) {
      counter += 1;
    }
    if (state?.user?.sect == data?.sect) {
      counter += 1;
    }
    if (state?.user?.profession == data?.profession) {
      counter += 1;
    }
    if (state?.user?.qualification == data?.qualification) {
      counter += 1;
    }
    if (state?.user?.financial_status == data?.financial_status) {
      counter += 1;
    }

    const percentage = Math.round((counter / total) * 100);

    return percentage;
  };

  const compatibiltyPercentage = compatibility();

  return (
    <>
      <FormModal
        className={"modal-md"}
        show={showPaymentModal}
        handleClose={() => setShowPaymentModal(false)}
      >
        <GetFeaturedPlan setClose={setShowPaymentModal} />
      </FormModal>
      <Profile
        setDegree={setDegree}
        userData={data}
        setEducation={setEducation}
        setProfession={setProfession}
        setDocument={[]}
        document={document}
        education={education}
        profession={profession}
        setImageModal={setImageModal}
        setShow={() => {}}
        user={false}
        setShowPaymentModal={setShowPaymentModal}
        questions={questions}
        setQuestions={setQuestions}
        compatibility={compatibiltyPercentage}
      />
      <ImageModal
        degree={degree}
        setImageModal={setImageModal}
        showImageModal={showImageModal}
      />
    </>
  );
}

function GetFeaturedPlan({ setClose }) {
  const [current, setCurrent] = useState(0);
  const state = useSelector((state) => {
    return state.user;
  });
  const param = useParams();
  const { id } = param;
  const [data, setData] = useState();
  const url =
    process.env.REACT_APP_API_URL + `api/user/get/other/profile/${id}`;

  useEffect(() => {
    const getUser = async () => {
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        })
        .then((response) => {
          setData(response?.data?.data);
        })
        .catch((err) => {
          if (err?.message === "Network Error" && state?.login == true) {
            toast.error("Check your internet Connection");
          }
        });
    };

    getUser();
  }, []);
  if (current == 0) {
    return (
      <div className="px-4 px-sm-5 pt-4 pb-5 featured-plan">
        <h6 className="text-center font-700 mb-3 ">User Detail</h6>
        <div className="image-holder mx-auto">
          {data?.gender == "Male" || data?.gender == "male" ? (
            <img
              className="w-100"
              src={
                data?.image
                  ? `${process.env.REACT_APP_API_URL}${data.image}`
                  : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
              }
              alt={data?.name || "Profile"}
            />
          ) : (
            <img
              className="w-100"
              src={
                data?.image
                  ? `${process.env.REACT_APP_API_URL}${data.image}`
                  : "https://cdn.vectorstock.com/i/500p/82/33/person-gray-photo-placeholder-woman-vector-24138233.jpg"
              }
              alt={data?.name || "Profile"}
            />
          )}
        </div>
        <h6
          style={{ textTransform: "capitalize" }}
          className="font-700 text-center mt-2"
        >
          {data?.name}
        </h6>
        <div>
          <h6 className="font-600 mb-1">Gender </h6>
          <p className="text-light mb-2">{data?.gender}</p>
          <h6 className="font-600 mb-1">Profession </h6>
          <p className="text-light mb-2">{data?.profession}</p>
          <h6 className="font-600 mb-1">Date of birth</h6>
          <p className="text-light">{data?.dob}</p>
        </div>
        <div className="text-center mt-5">
          <button
            className="btn clr-btn-theme px-4"
            onClick={() => setCurrent(1)}
          >
            Purchase a plan
          </button>
        </div>
      </div>
    );
  } else if (current == 1) {
    return <PaymentModal setClose={setClose} />;
  }
}

function PaymentModal({ setClose }) {
  const [loader, setLoader] = useState(false);
  const state = useSelector((state) => {
    return state.user;
  });
  const param = useParams();
  const { id } = param;
  const [data, setData] = useState();

  const url =
    process.env.REACT_APP_API_URL + `api/user/get/other/profile/${id}`;

  useEffect(() => {
    const getUser = async () => {
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        })
        .then((response) => {
          setData(response?.data?.data);
        })
        .catch((err) => {
          if (err?.message === "Network Error" && state?.login == true) {
            toast.error("Check your internet Connection");
          }
        });
    };

    getUser();
  }, []);

  const purchasePlan = async () => {
    setLoader(true);
    const formData = {
      other_id: id,
    };

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}api/user/feature/profile/${state?.user?.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.message == "Profile Already Featured") {
          toast.error("Profile Already Featured");
          setClose(false);
          setLoader(false);
        }
        if (res?.data?.message == "Profile Featured Successfully") {
          toast.success("Profile Featured Successfully");
          setClose(false);
          setLoader(false);
        }
      })
      .catch((err) => {
        toast.error("There is something went wrong!");
        setLoader(false);
        setLoader(false);
      });
  };

  return (
    <div className="px-4 px-sm-5 pt-4 pb-5">
      <h5 className="text-center font-700 mb-3">Payment</h5>
      <p className="font-500 text-small mb-2">Payment amount</p>
      <h3 className="font-700 mb-3">$50.00</h3>
      <img src={visaLogo} className="payment-icon mb-3" alt="" />
      <div className=" form-group mb-4">
        <label className="label form-label">Name</label>
        <input
          type="text"
          placeholder="Name on card"
          className="form-control"
        />
      </div>
      <div className="form-group mb-4">
        <label className="label form-label">Card number</label>
        <input
          type="email"
          placeholder="0000 0000 0000 0000"
          className="form-control"
        />
      </div>
      <div className="row">
        <div className="form-group mb-4 col-6">
          <label className="label form-label">Expiry</label>
          <input type="text" placeholder="MM/YY" className="form-control" />
        </div>
        <div className="form-group mb-4 col-6">
          <label className="label form-label">CVV</label>
          <input type="text" placeholder="CVV" className="form-control" />
        </div>
      </div>
      <div className="text-center mt-5">
        <button
          disabled={loader}
          className="btn clr-btn-theme px-5"
          onClick={purchasePlan}
        >
          {loader ? <Loading stroke="4" width="20" height="20" /> : "Pay $100"}
        </button>
      </div>
    </div>
  );
}
