import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../pages/GlobalProvider"; // Adjust import path
import filer from "../Assets/filter.png";
import SelectRegion from "./SelectRegion";
import axios from "axios";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { toast } from "react-toastify";

const Filters = () => {
  const { setFilters } = useContext(GlobalContext); // Global context to set filters

  const [e, setE] = useState(null);

  const initialFormData = {
    height: "",
    nativeCountry: "",
    nativeState: "",
    nativeCity: "",
    country: "",
    state: "",
    city: "",
    ethnicity: "",
    religion: "",
    cast: "",
    sects: [],
    sect: "",
    highestQualification: "",
    profession: "",
  };

  const location = useLocation();
  const navigate = useNavigate();
  const state = useSelector((state) => state?.user);

  const [professions, setUserProfession] = useState([]);
  const [qualification, setUserQualification] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [userData, setUserData] = useState({});
  const [error, setError] = useState();
  const [submitTriiger, setSubmitTrigger] = useState(false);

  // Parse query params from URL to prepopulate form fields
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    if (!queryParams?.religion || queryParams?.religion === "") {
      // Remove sect from queryParams if it exists
      if (queryParams.sect) {
        delete queryParams.sect;
      }

      // Update formData to remove sect
      setFormData((prev) => {
        const { sect, ...rest } = prev; // Destructure to exclude sect
        return rest; // Return the updated formData without sect
      });
    }

    setFormData({
      height: queryParams.height || "",
      nativeCountry: queryParams.native_country || "",
      nativeState: queryParams.native_state || "",
      nativeCity: queryParams.native_city || "",
      country: queryParams.country_of_residence || "",
      state: queryParams.state_of_residence || "",
      city: queryParams.city || "",
      ethnicity: queryParams.ethnicity || "",
      religion: queryParams.religion || "",
      cast: queryParams.cast || "",
      sects: [], // Populate this based on selected religion
      sect: queryParams.sect || "",
      highestQualification: queryParams.highest_qualification || "",
      profession: queryParams.profession || "",
    });
  }, [location.search]);

  // Fetch profession and qualification data for filters
  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}api/get/user/profile/${state?.user?.id}`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        setUserProfession((prev) => [...prev, ...res?.data?.professions]);
        setUserQualification((prev) => [...prev, ...res?.data?.qualifications]);
      } catch (err) {}
    };
    getUser();
  }, [state.user?.id, state?.token]);

  // Fetch signup data (e.g., religions, casts, etc.)
  async function fetchUserData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/user/signup/data`
      );
      setUserData(response.data);
    } catch (err) {
      if (state.login == true) {
      }
    }
  }

  useEffect(() => {
    fetchUserData();
  }, []);

  // Fetch sects based on selected religion
  async function getSects(e, religionId) {
    const { name, value } = e.target;

    // Update the form data
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (!value) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/user/sects/data/${religionId}`
      );

      if (response.data?.sects) {
        setFormData((prev) => ({
          ...prev,
          sects: response.data.sects,
        }));
      }
    } catch (error) {}
  }

  // Handle form field changes
  function onChange(e) {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  // Submit the form and update the URL with filter query params
  const formSubmission = () => {
    if (state?.login == false) {
      toast.error("Login to apply filters");
      return;
    }
    window.scrollTo(0, 0);

    const params = Object.fromEntries(
      Object.entries({
        height: formData.height,
        native_country: formData.nativeCountry.split(",").pop().trim(),
        native_city: formData.nativeCity,
        city: formData.city,
        native_state: formData.nativeState.split(",").pop().trim(),
        country_of_residence: formData.country.split(",").pop().trim(),
        state_of_residence: formData.state.split(",").pop().trim(),
        religion: formData.religion,
        ethnicity: formData.ethnicity,
        highest_qualification: formData.highestQualification,
        cast: formData.cast,
        sect: formData.sect,
        profession: formData.profession,
      }).filter(([_, value]) => value)
    );

    const queryStringParams = queryString.stringify(params);
    setFilters(params);
    navigate(`?${queryStringParams}`);
    setSubmitTrigger(!submitTriiger);
    toast.success("Filters applied");
  };

  const handleClearFilters = () => {
    window.scrollTo(0, 0);
    setFilters({});
    navigate("?");
    toast.success("All filters are cleared");
  };

  const changer = (e) => {
    const selectedReligion = userData?.religions?.find(
      (religion) => religion.religion === e?.target?.value
    );

    // Call getSects function if religion is found
    if (selectedReligion?.id) {
      getSects(e, selectedReligion?.id);
    }
  };

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    if (formData.religion !== "" && queryParams.religion) {
      const selectedReligion = userData.religions?.find(
        (religion) => religion.religion === formData.religion
      );
      if (selectedReligion?.id) {
        getSects(
          { target: { value: selectedReligion.religion } },
          selectedReligion.id
        );
      }
    }
  }, [submitTriiger, formData.religion]);

  return (
    <div className="mt-3 pb-3 px-3 background-container">
      <div className="pt-sm-4 pb-sm-3 d-flex align-items-center justify-content-between">
        <h5 className="mb-0 font-600">Set Preferences</h5>
        <img src={filer} width={24} />
      </div>

      <div className="form-group mb-3 position-relative">
        <label className="mb-2 label">Height</label>

        <select
          className="form-select "
          value={formData.height}
          onChange={onChange}
          name="height"
        >
          <option value="" defaultValue disabled selected>
            Select Height
          </option>
          {Array.from({ length: (7 - 3) * 12 + 1 }, (_, i) => {
            const totalFeet = Math.floor(i / 12) + 3; // Calculate the feet (3 to 7)
            const totalInches = i % 12; // Calculate the inches (0 to 11)
            const height =
              totalInches === 0
                ? `${totalFeet} feet`
                : `${totalFeet}.${totalInches}  feet`; // Format the height without .0
            return (
              <option key={height} value={height}>
                {height}
              </option>
            );
          })}
        </select>
        {formData.height && (
          <span
            onClick={() => {
              setFormData((prev) => ({
                ...prev,
                height: "",
              }));
            }}
            style={{ top: "63%", right: "14%" }}
            className="fa-solid fa-xmark text-danger position-absolute"
          ></span>
        )}
      </div>

      <SelectRegion
        labels={["Native Country", "Native state", "Native City"]}
        country={formData.nativeCountry}
        state={formData.nativeState}
        city={formData.nativeCity}
        formData={formData}
        setFormData={setFormData}
        onChange={onChange}
        setError={setError}
        flag={false}
        flag1={true}
        flag2={true}
        names={["nativeCountry", "nativeState", "nativeCity"]}
        countryType="Native Country"
      />
      <SelectRegion
        setError={setError}
        labels={[
          "Country of Residence",
          "State of Residence",
          "City of Residence",
        ]}
        country={formData.country}
        state={formData.state}
        onChange={onChange}
        formData={formData}
        setFormData={setFormData}
        flag={false}
        flag1={true}
        flag2={true}
        names={["country", "state", "city"]}
        countryType="Country"
      />

      <div className="form-group mb-3 position-relative">
        <label className="mb-2 label">Ethnicity</label>
        <select
          className="form-select"
          value={formData.ethnicity}
          name="ethnicity"
          onChange={onChange}
        >
          <option value="" defaultValue disabled selected>
            Select
          </option>
          {userData.ethnicity?.map((eth, index) => (
            <option value={eth.name} key={index + eth.name}>
              {eth.name}
            </option>
          ))}
        </select>
        {formData.ethnicity && (
          <span
            onClick={() => {
              setFormData((prev) => ({
                ...prev,
                ethnicity: "",
              }));
            }}
            style={{ top: "63%", right: "14%" }}
            className="fa-solid fa-xmark text-danger position-absolute"
          ></span>
        )}
      </div>
      <div className="form-group mb-3 position-relative">
        <label className="mb-2 label">Religion</label>
        <select
          className="form-select"
          value={formData.religion}
          name="religion"
          onChange={(e) => {
            setE(e);
            onChange(e); // Update form data
            changer(e);
          }}
        >
          <option value="" defaultValue disabled selected>
            Select
          </option>
          {userData.religions?.map((religion, index) => {
            return (
              <option
                value={religion.religion}
                key={religion.id + religion.religion}
              >
                {religion.religion}
              </option>
            );
          })}
        </select>
        {formData.religion && (
          <span
            onClick={() => {
              setFormData((prev) => ({
                ...prev,
                religion: "",
              }));
            }}
            style={{ top: "63%", right: "14%" }}
            className="fa-solid fa-xmark text-danger position-absolute"
          ></span>
        )}
      </div>
      <div className="form-group mb-3 position-relative">
        <label className="mb-2 label">Cast</label>
        <select
          className="form-select"
          value={formData.cast}
          name="cast"
          onChange={onChange}
        >
          <option value="" defaultValue disabled selected>
            Select
          </option>
          {userData.casts?.map((cast, index) => (
            <option value={cast.name} key={index + cast.name}>
              {cast.name}
            </option>
          ))}
        </select>
        {formData.cast && (
          <span
            onClick={() => {
              setFormData((prev) => ({
                ...prev,
                cast: "",
              }));
            }}
            style={{ top: "63%", right: "14%" }}
            className="fa-solid fa-xmark text-danger position-absolute"
          ></span>
        )}
      </div>
      <div className="form-group mb-3 position-relative">
        <label className="mb-2 label">Sect</label>
        <select
          className="form-select"
          value={formData.sect}
          name="sect"
          onChange={onChange}
        >
          <option value="" defaultValue disabled selected>
            Select
          </option>
          {formData.sects?.map((sect, index) => (
            <option key={index + sect.name} value={sect.name}>
              {sect.name}
            </option>
          ))}
        </select>
        {formData.sect && (
          <span
            onClick={() => {
              setFormData((prev) => ({
                ...prev,
                sect: "",
              }));
            }}
            style={{ top: "63%", right: "14%" }}
            className="fa-solid fa-xmark text-danger position-absolute"
          ></span>
        )}
      </div>
      <div className="form-group mb-3 position-relative">
        <label className="mb-2 label">Highest Qualification</label>
        <select
          style={{ paddingRight: "50px" }}
          className="form-select"
          value={formData.highestQualification}
          name="highestQualification"
          onChange={onChange}
        >
          <option value="" defaultValue disabled selected>
            Select
          </option>
          {qualification
            ?.filter(
              (category, index, self) =>
                index === self.findIndex((cat) => cat.id === category.id)
            )
            ?.map((category, index) => (
              <optgroup
                key={category.id}
                label={category.name}
                style={{ fontWeight: "400" }}
              >
                {category.degree.map((degreeItem) => (
                  <option key={index} value={degreeItem.degree}>
                    {degreeItem.degree}
                  </option>
                ))}
              </optgroup>
            ))}
        </select>
        {formData.highestQualification && (
          <span
            onClick={() => {
              setFormData((prev) => ({
                ...prev,
                highestQualification: "",
              }));
            }}
            style={{ top: "63%", right: "14%" }}
            className="fa-solid fa-xmark text-danger position-absolute"
          ></span>
        )}
      </div>
      <div className="form-group mb-3 position-relative">
        <label className="mb-2 label">Profession</label>
        <select
          style={{ paddingRight: "50px" }}
          className="form-select"
          value={formData.profession}
          name="profession"
          onChange={onChange}
        >
          <option value="" defaultValue disabled selected>
            Select
          </option>
          {professions
            ?.filter((profession) => profession.status === "1")
            ?.map((profession) => (
              <option key={profession.id} value={profession.name}>
                {profession.name}
              </option>
            ))}
        </select>
        {formData.profession && (
          <span
            onClick={() => {
              setFormData((prev) => ({
                ...prev,
                profession: "",
              }));
            }}
            style={{ top: "63%", right: "14%" }}
            className="fa-solid fa-xmark text-danger position-absolute"
          ></span>
        )}
      </div>
      <div className="d-flex justify-content-center gap-2 mt-4">
        <button
          onClick={handleClearFilters}
          className="btn transparent-btn-theme w-50"
        >
          Clear
        </button>
        <button
          onClick={formSubmission}
          className="btn auth-btn clr-btn-theme register-btn py-2 w-50"
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default Filters;
