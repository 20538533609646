import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { logOutUser } from "../store/authSlices";
import { toast } from "react-toastify";
import logo from "../Assets/logo.png";
import axios from "axios";
import Loading from "./Loading";
const Header = () => {
  const [navBar, openNavBar] = useState(false);
  const [notify, setNotify] = useState(true);
  const selector = useSelector((state) => {
    return state.user;
  });
  const [login, setLogin] = useState(false);

  useEffect(() => {
    if (selector.login === true) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, [selector]);

  return (
    <div style={{ zIndex: 1000 }} className="mb-4 position-sticky top-0">
      <nav
        style={{
          boxShadow: "0px 0px 5px 0px rgb(128 128 128 / 45%)",
        }}
        className="py-3  navbar px-lg-2 navbar-expand-lg bg-white"
      >
        <div className="container-fluid position-relative px-xl-5 px-sm-4">
          <NavLink className="d-flexalign-items-center navbar-brand" to="/">
            <img src={logo} alt="" className="header-logo p-0 m-0" />
          </NavLink>
          <div className="d-lg-none">
            <div className="d-flex gap-2 align-items-center">
              <span
                className="navbar-toggler-icon"
                onClick={() => openNavBar(!navBar)}
              ></span>
              <DropDown
                state={selector}
                image={selector?.user?.image}
                gender={selector?.user?.gender}
              />
            </div>
          </div>
          <div className={"collapse navbar-collapse " + (navBar ? "show" : "")}>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to="/" className="nav-link">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/wishlist" className="nav-link">
                  Wishlist
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/feeds" className="nav-link">
                  Feeds
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/ads-listing" className="nav-link">
                  Ads
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={`/chat`} className="nav-link">
                  Chat
                </NavLink>
              </li>
            </ul>
            {login == false ? (
              <form
                className="d-flex justify-content-center justify-content-sm-start"
                role="search"
              >
                <NavLink
                  to="/register"
                  className="me-3 btn auth-btn transparent-btn-theme"
                >
                  Register
                </NavLink>
                <NavLink to="/sign-in">
                  <button className="btn auth-btn clr-btn-theme" type="submit">
                    Sign In
                  </button>
                </NavLink>
              </form>
            ) : (
              <DropDown
                image={selector?.user?.image}
                username={selector?.user?.name}
                profession={selector?.user?.profession}
                gender={selector?.user?.gender}
                state={selector}
              />
            )}
          </div>
        </div>
      </nav>
      {notify &&
        selector?.login == true &&
        selector?.user?.feature_profile != 1 && (
          <div>
            <div
              style={{ background: "#1d2c42" }}
              className="py-2 container-fluid position-relative px-xl-5 px-sm-4 d-flex justify-content-between gap-3 align-items-center"
            >
              <p
                style={{ fontSize: "0.8rem", color: "white" }}
                className="m-0 p-0 fw-light"
              >
                ✨ Feature your profile for premium perks, or make someone’s day
                by showcasing theirs for exclusive benefits! 🎉
              </p>
              <span
                onClick={() => {
                  setNotify(false);
                }}
                className="fa-solid fa-xmark text-white pointer"
              ></span>
            </div>
          </div>
        )}
    </div>
  );
};

function DropDown(props) {
  const state = props?.state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profDrop, openProfDrop] = useState(false);
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const [notificationsData, setNotificationsData] = useState([]);
  const [options, setOptions] = useState(false);
  const [loading, setLoading] = useState(false);

  const refBell = useRef();
  const refProf = useRef();
  const refNotification = useRef();
  const refNotificationContainer = useRef();
  const option = useRef();

  const toggleNotificationDropdown = () => {
    setNotificationDropDown((prev) => !prev);
  };

  const showOptions = () => {
    setOptions((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (refBell.current && !refBell.current.contains(event.target)) {
      setNotificationDropDown(false);
    }

    if (option.current && !option.current.contains(event.target)) {
      setOptions(false);
      setNotificationDropDown(true);
    }

    if (
      option.current &&
      !option.current.contains(event.target) &&
      refBell.current &&
      !refBell.current.contains(event.target)
    ) {
      setNotificationDropDown(false);
    }

    if (refProf.current && !refProf.current.contains(event.target)) {
      openProfDrop(false);
    }

    if (
      refNotification.current &&
      refNotification.current.contains(event.target)
    ) {
      setNotificationDropDown(true);
    }
  };

  useEffect(() => {
    if (notificationDropDown && refNotificationContainer.current) {
      setTimeout(() => {
        refNotificationContainer.current.scrollTop =
          refNotificationContainer.current.scrollHeight;
      }, 0);
    }
  }, [notificationDropDown]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      await axios
        .get(
          process.env.REACT_APP_API_URL +
            `api/user/notifications/${state?.user?.id}`,
          {
            headers: {
              Authorization: `Bearer ${state?.token}`,
            },
          }
        )
        .then((res) => {
          setNotificationsData(res?.data?.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (state.login == true) {
            toast.error("There is something went wrong!");
          }
        });
    };

    fetchNotifications();
  }, [state?.user?.id, state?.token]);

  const seenByUser = async (notId) => {
    setNotificationsData((prevData) =>
      prevData.map((notification) =>
        notification.id === notId
          ? { ...notification, seen_by_user: "1" }
          : notification
      )
    );
    await axios
      .post(
        process.env.REACT_APP_API_URL + `api/seen/user/notification/${notId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        }
      )
      .then((res) => {
        // if (res?.data?.message === "notification seen successfully") {
        //   setNotificationsData((prevData) =>
        //     prevData.map((notification) =>
        //       notification.id === notId
        //         ? { ...notification, seen_by_user: "1" }
        //         : notification
        //     )
        //   );
        // }
      })
      .catch((err) => {});
  };

  const removeNotification = async (id) => {
    await axios
      .delete(`${process.env.REACT_APP_API_URL}api/delete/notification/${id}`, {
        headers: {
          Authorization: `Bearer ${state?.token}`,
        },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          toast.success("Notification removed");
          setNotificationsData((prevData) =>
            prevData.filter((e) => e?.id !== id)
          );
        }
      })
      .catch((err) => {
        if (err?.message === "Network Error" && state?.login == true) {
          toast.error("Check your internet Connection");
        }
      });
  };

  const unseenCount = notificationsData.filter(
    (e) => e.seen_by_user === "0"
  ).length;

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const clearAllNotifications = async (e) => {
    e.preventDefault();

    await axios
      .delete(
        `${process.env.REACT_APP_API_URL}api/clearAllNotification/${state?.user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.status === "success") {
          toast.success("All Notifications are removed");
          setNotificationsData([]);
        }
      })
      .catch((err) => {
        if (err?.message === "Network Error" && state?.login == true) {
          toast.error("Check your internet Connection");
        }
      });
  };

  return (
    <>
      {props?.state?.login && (
        <div className="d-flex gap-3 align-items-center bell-prof-icon">
          <div
            onClick={toggleNotificationDropdown}
            ref={refBell}
            className="d-flex justify-content-center position-relative align-items-center bell-icon"
          >
            <span
              style={{ fontSize: "22px" }}
              className="fa-solid fa-bell"
            ></span>
            {unseenCount > 0 && (
              <span className="notificaton-count">{unseenCount}</span>
            )}
          </div>
          {notificationDropDown && (
            <div
              ref={refNotification}
              className="rounded-3 overflow-hidden header-dropdown-container shadow"
            >
              <div
                ref={refNotificationContainer}
                className="header-dropdown  position-relative overflow-y-auto notification-section"
              >
                <div className="position-sticky top-0">
                  <div className="p-3  notification-heading d-flex justify-content-between align-items-center ">
                    <h4 className="text-white p-0 m-0">Notifications</h4>
                    <span
                      ref={option}
                      onClick={showOptions}
                      className="fa-solid fa-ellipsis text-white h5 p-0 m-0"
                    ></span>
                  </div>

                  <div className="position-relative">
                    {options && (
                      <div
                        onClick={clearAllNotifications}
                        className="p-2 position-absolute bg-white rounded-2 shadow-lg notification-options"
                      >
                        <p className="p-0 m-0 pointer">
                          Clear all notification
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {loading ? (
                  <div className="w-100 h-75 d-flex justify-content-center align-items-center">
                    <Loading stroke={4} />
                  </div>
                ) : (
                  <>
                    {" "}
                    {notificationsData.length > 0 ? (
                      notificationsData.map((e, index) => (
                        <div
                          key={index}
                          onClick={() => seenByUser(e?.id)}
                          className={`pb-2 border-bottom pointer  ${
                            e?.seen_by_user == "1"
                              ? "notification-border"
                              : "notificationBackground border-light"
                          } notifications`}
                        >
                          <div className="d-flex justify-content-between align-items-center mb-1 p-2 notification-title">
                            <h6 className="p-0 m-0">{e?.title}</h6>
                            <span
                              onClick={(event) => {
                                event.stopPropagation();
                                removeNotification(e?.id);
                              }}
                              className="fa-solid fa-trash text-small"
                            ></span>
                          </div>
                          <p className="p-2 m-0 notification-text">
                            {e?.description}
                          </p>
                          <span className="p-2 mt-1 text-light notification-time">
                            {formatDate(e?.created_at)}
                          </span>
                        </div>
                      ))
                    ) : (
                      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                        <p className="text-light">No new notifications</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          <div
            ref={refProf}
            className="d-flex pointer gap-2 align-items-center prof-dropdown"
            onClick={() => openProfDrop((prev) => !prev)}
          >
            <div>
              {props.gender == "Male" || props.gender == "male" ? (
                <img
                  className="user-img-header"
                  src={
                    props?.image
                      ? `${process.env.REACT_APP_API_URL + props.image}`
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  }
                  alt=""
                />
              ) : (
                <img
                  className="user-img-header"
                  src={
                    props?.image
                      ? `${process.env.REACT_APP_API_URL + props.image}`
                      : "https://cdn.vectorstock.com/i/500p/82/33/person-gray-photo-placeholder-woman-vector-24138233.jpg"
                  }
                  alt=""
                />
              )}
            </div>
            <div>
              <h6 className="mb-0 d-flex justify-content-between align-items-center">
                <span
                  style={{ textTransform: "capitalize" }}
                  className="h-username"
                >
                  {props?.username?.length > 7
                    ? props.username.slice(0, 7) + "..."
                    : props.username}
                </span>
                <span className="fa-solid fa-angle-down"></span>
              </h6>
              <p className="h-prof mb-0 text-smallest text-light">
                {props.profession
                  ? props.profession.length > 20
                    ? `${props.profession.slice(0, 20)}...`
                    : props.profession
                  : ""}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Profile Dropdown */}
      {profDrop && (
        <div className="header-dropdown-container">
          <div className="header-dropdown rounded position-relative">
            <NavLink to="/user-profile" className="drop-down-links px-2 py-1">
              <span className="fa-regular fa-user"></span> My Profile
            </NavLink>
            <NavLink to="/my-feeds" className="drop-down-links px-2 py-1">
              <span className="fa-solid fa-paper-plane"></span> My Feeds
            </NavLink>
            <button
              onClick={() => {
                dispatch(logOutUser());
                navigate("/");
                toast.success("Sign Out successfully");
              }}
              className="drop-down-links pointer px-2 py-1 border-0 bg-white p-0 m-0"
            >
              <span className="fa-solid fa-right-from-bracket"></span> Sign Out
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
