import React from "react";
import { Modal } from "react-bootstrap";

export default function ImageModal({ showImageModal, setImageModal, degree }) {
  return (
    <Modal
      show={showImageModal}
      onHide={() => setImageModal(false)}
      className={`modal-md`}
      centered
    >
      <Modal.Body className="p-0">
        {degree != "https://admin.sageswedlock.com/null" ? (
          <img style={{ width: "100%" }} src={degree} alt="" />
        ) : (
          <p className="text-grey m-0 small text-center py-2">
            No document provided
          </p>
        )}
      </Modal.Body>
    </Modal>
  );
}
