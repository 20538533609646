import heart from "../Assets/heart.png";
import heart2 from "../Assets/heart 2.png";
import verifiedUser from "../Assets/verified_user.png";
import axios from "axios";
import featuredIcon from "../Assets/featured-icon.png";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const UserProfiles = ({ data, setData, flag = false }) => {
  const [wishlistState, setWishlistState] = useState({}); // Change to object to map by user.id
  const navigate = useNavigate();

  const state = useSelector((state) => state.user);

  async function addToWishlist(memberId, name) {
    if (state.login == false) {
      toast.error("Login to add profile to wishlist");
      return;
    }
    const formData = {
      user_id: state?.user?.id,
      member_id: memberId,
    };

    await axios
      .post(process.env.REACT_APP_API_URL + "api/user/wishlist", formData, {
        headers: {
          Authorization: `Bearer ${state?.token}`,
        },
      })
      .then((res) => {
        fetchWishlist();
        if (res?.data?.message == "User added to wishlist successfully") {
          toast.success(`${name} is added to wishlist`);
        }
        if (res?.data?.message == "User removed from wishlist successfully") {
          toast.success(`${name} is remove from wishlist`);
          if (flag == true) {
            setData((prev) => prev.filter((item) => item.id !== memberId));
            return;
          }
        }
      })
      .catch((err) => {
        if (err?.message === "Network Error" && state?.login == true) {
          toast.error("Check your internet Connection");
        }
      });
  }

  const fetchWishlist = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `api/get/user/wishlist/members/${state?.user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        }
      );

      const wishlistMap = await response?.data?.data?.reduce((acc, member) => {
        acc[member.id] = true;
        return acc;
      }, {});

      setWishlistState(wishlistMap || {});
    } catch (err) {}
  };

  useEffect(() => {
    fetchWishlist();
  }, []);

  return (
    <div className="row same-passion-section">
      {data?.map((user, index) => {
        // Compatibility calculation function
        const compatibility = () => {
          const total = 5;
          let counter = 0;

          // Check for matching fields
          if (state?.user?.religion === user?.religion) {
            counter += 1;
          }
          if (state?.user?.sect === user?.sect) {
            counter += 1;
          }
          if (state?.user?.profession === user?.profession) {
            counter += 1;
          }
          if (state?.user?.qualification === user?.qualification) {
            counter += 1;
          }

          // Calculate percentage
          const percentage = Math.round((counter / total) * 100);
          return percentage;
        };

        // Get compatibility percentage
        const compatibilityPercentage = compatibility();

        // Return JSX for each user
        return (
          <div className="col-sm-4 col-6 mb-4" key={user?.id}>
            <div className="position-relative d-flex justify-content-center grey-background">
              {user?.feature_profile == "1" ? (
                <img
                  src={featuredIcon}
                  alt=""
                  className="position-absolute featured-image"
                />
              ) : (
                ""
              )}
              <span className="percentage">{compatibilityPercentage}%</span>
              <img
                className="position-absolute heart-icon"
                src={wishlistState[user?.id] ? heart : heart2}
                onClick={() => {
                  addToWishlist(user?.id, user?.name);
                }}
              />
              <Link to={`/visit-user-profile/${user?.id}`} className="">
                <div className="mt-2  position-relative image-holder">
                  {user.gender == "Male" || user.gender == "male" ? (
                    <img
                      src={
                        user.image
                          ? `${process.env.REACT_APP_API_URL}${user?.image}`
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                      }
                      alt={user?.name || "Profile"}
                    />
                  ) : (
                    <img
                      src={
                        user.image
                          ? `${process.env.REACT_APP_API_URL}${user?.image}`
                          : "https://cdn.vectorstock.com/i/500p/82/33/person-gray-photo-placeholder-woman-vector-24138233.jpg"
                      }
                      alt={user?.name || "Profile"}
                    />
                  )}
                </div>
              </Link>
            </div>
            <div className="pt-5 pb-3 position-relative same-passion-details">
              <Link
                to={`/visit-user-profile/${user?.id}`}
                className="text-decoration-none text-dark"
              >
                {user.name ? (
                  <div className="d-flex justify-content-center align-items-center flex-wrap gap-2 mb-1">
                    <h5
                      style={{ textTransform: "capitalize" }}
                      className="mt-1 mb-0"
                    >
                      {user.name.length > 8
                        ? user.name.slice(0, 8) + "..."
                        : user.name}
                    </h5>
                  </div>
                ) : (
                  <h5>N/A</h5>
                )}
              </Link>
              {user?.highest_qualification ? (
                <p className="mb-1 text-small">
                  {user?.highest_qualification.length > 8
                    ? `${user?.highest_qualification.slice(0, 8)}...`
                    : user?.highest_qualification}
                </p>
              ) : (
                <p className="mb-1 text-small">N/A</p>
              )}
              {user?.profession ? (
                <p className="mb-2 text-small">
                  {user?.profession.length > 20
                    ? `${user?.profession.slice(0, 20)}...`
                    : user?.profession}
                </p>
              ) : (
                <p className="mb-1 text-small">N/A</p>
              )}
              <button
                onClick={() => navigate(`/chat/${user?.id}`)}
                className="py-1 btn transparent-btn-theme"
              >
                Message
              </button>
              {user?.verified == "1" && (
                <div className="position-absolute img-holder">
                  <img
                    src={verifiedUser}
                    className="verified-symbol"
                    alt="Verified"
                  />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UserProfiles;
