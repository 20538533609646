import MyInfo from "../Components/MyInfo";
import Passions from "../Components/Passions";
import FormModal from "../Components/FormModal";
import { useEffect, useState } from "react";
import Ads from "../Components/Ads";
import FeaturedProfiles from "../Components/FeaturedProfiles";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "../Components/Loading";
import { logOutUser } from "../store/authSlices";
import Download from "../Components/Download";

const MyFeeds = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => {
    return state.user;
  });

  const [words, setWords] = useState(0);

  const arr1 = [];
  const arr2 = [];

  const userId = state?.user?.id;
  const token = state?.token;

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [feed, setFeed] = useState([]);
  const [text, setText] = useState("");
  const [error, setError] = useState(false);
  const wordLimit = 300;
  const handleCopy = (e) => e.preventDefault();
  const handleCut = (e) => e.preventDefault();
  const handlePaste = (e) => e.preventDefault();

  // Function to count words in a string
  const countWords = (str) => {
    return str
      .trim()
      .split(/\s+/)
      .filter((word) => word !== "").length;
  };

  const handleChange = (e) => {
    const inputText = e.target.value;

    if (inputText !== "") {
      setError(false);
    }

    const wordsCount = countWords(inputText);

    // Only update if word count is within the limit
    if (wordsCount <= wordLimit) {
      setText(inputText);
      setWords(wordsCount); // Update words count in state
    }
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/user/get/my/feeds/${userId}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setFeed(response.data.feeds);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message == "Token has expired") {
          toast.error("Your token has been expired again sign in");
          dispatch(logOutUser());
          return;
        }
        if (err?.message === "Network Error" && state?.login == true) {
          toast.error("Check your internet Connection");
        }
      });
  }, [loading]);

  const handlePost = async () => {
    if (text == "") {
      setError(true);
      return;
    }

    const formData = {
      description: text,
    };
    setBtnLoading(true);
    await axios
      .post(
        process.env.REACT_APP_API_URL + `api/user/post/feed/${state?.user?.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.message == "Your Profile is not Featured") {
          toast.error("Featured your profile to make a post");
          setBtnLoading(false);
          setShow(false);
          setText("");
          return;
        }
        setLoading(true);
        toast.success(res?.data?.message);
        setBtnLoading(false);
        setShow(false);
        setText("");
      })
      .catch((err) => {
        if (err?.response?.data?.message == "Token has expired") {
          toast.error("Your token has been expired again sign in");
          dispatch(logOutUser());
          return;
        }
        toast.error(err?.data?.message);
        setBtnLoading(false);
        setShow(true);
        setText("");
      });
  };

  return (
    <>
      <FormModal
        className={"modal-lg"}
        show={show}
        handleClose={() => setShow(false)}
      >
        <div className="px-lg-3">
          <div className="p-4 p-lg-5 send-message-modal">
            <div className="d-flex gap-3 mb-3 align-items-center">
              <div className="image-holder">
                {state?.user?.gender == "Male" ||
                state?.user?.gender == "male" ? (
                  <img
                    className="user-img-in-message-modal"
                    src={
                      state?.user?.image
                        ? `${process.env.REACT_APP_API_URL + state.user.image}`
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    alt=""
                  />
                ) : (
                  <img
                    className="user-img-in-message-modal"
                    src={
                      state?.user?.image
                        ? `${process.env.REACT_APP_API_URL + state.user.image}`
                        : "https://cdn.vectorstock.com/i/500p/82/33/person-gray-photo-placeholder-woman-vector-24138233.jpg"
                    }
                    alt=""
                  />
                )}
              </div>
              <div>
                <h6
                  style={{ textTransform: "capitalize" }}
                  className="mb-1 font-600"
                >
                  {state?.user?.name ? state?.user?.name : "User Name"}
                </h6>
                <p className="text-small text-light mb-0">
                  {state?.user?.profession}
                </p>
              </div>
            </div>
            <div className="ps-sm-3 form-group text-container">
              <textarea
                value={text}
                name="post"
                onChange={handleChange}
                // onCopy={handleCopy}
                // onCut={handleCut}
                // onPaste={handlePaste}
                rows={7}
                placeholder="Type here"
                className="form-control p-3"
              ></textarea>
              {error && (
                <p className="text-danger mt-2 text-small">
                  Post should not be empty
                </p>
              )}
              <p
                className={`${
                  words == 300 ? "text-danger" : "text-light"
                } mt-2`}
              >
                Words: {words}/300
              </p>
            </div>
            <div className="text-center">
              <button
                disabled={btnLoading}
                onClick={handlePost}
                className="btn auth-btn clr-btn-theme feed-btn"
              >
                {btnLoading ? (
                  <Loading stroke="4" width="20" height="20" />
                ) : (
                  "Post"
                )}
              </button>
            </div>
          </div>
        </div>
      </FormModal>
      <div className="container-fluid px-xl-5 px-sm-4">
        <div className="row mx-0">
          <div className="col-xl-3 px-xl-2 p-0">
            <MyInfo />
          </div>
          <div className="my-xl-0 my-3 col-xl-6 px-xl-2 p-0">
            <div className="mb-3 p-sm-4 p-2 background-container">
              <div className="d-flex align-items-center feed-post-input">
                <div className="me-3">
                  <div className="img-holder">
                    {state?.user?.gender == "Male" ||
                    state?.user?.gender == "male" ? (
                      <img
                        src={
                          state?.user?.image
                            ? `${
                                process.env.REACT_APP_API_URL + state.user.image
                              }`
                            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                        }
                      />
                    ) : (
                      <img
                        src={
                          state?.user?.image
                            ? `${
                                process.env.REACT_APP_API_URL + state.user.image
                              }`
                            : "https://cdn.vectorstock.com/i/500p/82/33/person-gray-photo-placeholder-woman-vector-24138233.jpg"
                        }
                      />
                    )}
                  </div>
                </div>
                <input
                  className="w-100"
                  onClick={() => setShow(true)}
                  placeholder={`Is there anything you'd like to share, ${state?.user?.name}?`}
                  readOnly
                />
              </div>
            </div>
            <div className="p-sm-4 p-3 background-container">
              {loading ? (
                <Loading stroke={4} />
              ) : (
                feed?.map((e, index) => {
                  return (
                    <div key={index}>
                      <UserFeed
                        e={e}
                        setFeed={setFeed}
                        state={state}
                        setLoading={setLoading}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
          <div className="col-xl-3 px-xl-2 p-0">
            <Passions />
            <Download />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyFeeds;

const UserFeed = ({ e, setFeed, state, setLoading }) => {
  const userFeed = e;
  const deletePost = async (id) => {
    setLoading(true);
    await axios
      .delete(`${process.env.REACT_APP_API_URL}api/user/delete/my/feed/${id}`, {
        headers: {
          Authorization: `Bearer ${state?.token}`,
        },
      })
      .then((res) => {
        if (res?.data?.message == "Feed deleted successfully") {
          toast.success("Feed deleted successfully");
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err?.message === "Network Error" && state?.login == true) {
          toast.error("Check your internet Connection");
        }
      });
  };

  return (
    <>
      <div className="mb-4 d-flex border-bottom user-feed">
        <div className="me-2">
          <div className="img-holder">
            {userFeed?.user?.gender == "Male" ? (
              <img
                src={
                  userFeed?.user?.image
                    ? `${process.env.REACT_APP_API_URL + userFeed.user.image}`
                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                }
              />
            ) : (
              <img
                src={
                  userFeed?.user?.image
                    ? `${process.env.REACT_APP_API_URL + userFeed.user.image}`
                    : "https://cdn.vectorstock.com/i/500p/82/33/person-gray-photo-placeholder-woman-vector-24138233.jpg"
                }
              />
            )}
          </div>
        </div>
        <div className="w-100">
          <div className="mt-2 mb-3 d-flex justify-content-between">
            <div>
              <h4
                style={{ textTransform: "capitalize" }}
                className="mb-0 username"
              >
                {userFeed?.user?.name}
              </h4>
              <p className="mb-0 profession">{userFeed?.user?.profession}</p>
            </div>
            <div>
              <span
                className="fa-solid fa-trash text-danger"
                onClick={() => deletePost(userFeed?.id)}
              ></span>
            </div>
          </div>
          <p>{userFeed?.description}</p>
        </div>
      </div>
    </>
  );
};
