import featuredIcon from "../Assets/featured-icon.png";
import heart from "../Assets/heart.png";
import heart2 from "../Assets/heart 2.png";
import axios from "axios";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const FeaturedProfiles = ({ data, setData, flag }) => {
  const navigate = useNavigate();
  const [wishlistState, setWishlistState] = useState({}); // Change to object to map by user.id

  const state = useSelector((state) => state.user);

  async function addToWishlist(memberId, name) {
    if (state.login == false) {
      toast.error("Login to add profile to wishlist");
      return;
    }
    const formData = {
      user_id: state?.user?.id,
      member_id: memberId,
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "api/user/wishlist",
        formData,
        {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        }
      );
      if (res?.data?.message == "User added to wishlist successfully") {
        toast.success(`${name} is added to wishlist`);
      }
      if (res?.data?.message == "User removed from wishlist successfully") {
        toast.success(`${name} is removed from wishlist`);
        if (flag === true) {
          setData((prev) => prev.filter((item) => item.id !== memberId));
          return;
        }
      }
      // Fetch wishlist after adding/removing
      fetchWishlist();
    } catch (err) {
      if (err?.message === "Network Error" && state?.login === true) {
        toast.error("Check your internet connection");
      }
    }
  }

  const fetchWishlist = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `api/get/user/wishlist/members/${state?.user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        }
      );

      // Convert wishlist data to an object mapping memberId to wishlist status
      const wishlistMap = response?.data?.data?.reduce((acc, member) => {
        acc[member.id] = true; // Assuming the response gives users in the wishlist
        return acc;
      }, {});

      setWishlistState(wishlistMap || {});
    } catch (err) {}
  };

  const handleClick = (id) => {
    navigate(`/chat/${id}`);
  };

  useEffect(() => {
    if (state?.user?.id) {
      fetchWishlist();
    }
  }, [state?.user?.id]);

  return (
    <>
      {data?.map((featuredUser, index) => {
        let newHobbiesString = null;
        if (featuredUser?.hobbies) {
          const hobbiesString = featuredUser?.hobbies
            ?.replace(/[\[\]"]/g, "")
            ?.split(",")
            ?.map((hobby) => hobby.trim())
            ?.join(", ");

          newHobbiesString = hobbiesString?.replace(/\\/g, "");
        }

        return (
          <div
            key={featuredUser?.id}
            className="featured-profiles background-container"
          >
            <div className="position-relative p-3 d-flex align-items-end justify-content-between grey-background">
              <img
                className="position-absolute heart-icon"
                src={wishlistState[featuredUser?.id] ? heart : heart2}
                onClick={() => {
                  addToWishlist(featuredUser?.id, featuredUser?.name);
                }}
              />
              <div className="d-flex align-items-end">
                <div className="overflow-hidden image-holder">
                  {featuredUser.gender == "Male" ||
                  featuredUser.gender == "male" ? (
                    <img
                      src={
                        featuredUser.image
                          ? `${process.env.REACT_APP_API_URL}${featuredUser.image}`
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                      }
                      alt={featuredUser.name || "Profile"}
                    />
                  ) : (
                    <img
                      src={
                        featuredUser.image
                          ? `${process.env.REACT_APP_API_URL}${featuredUser.image}`
                          : "https://cdn.vectorstock.com/i/500p/82/33/person-gray-photo-placeholder-woman-vector-24138233.jpg"
                      }
                      alt={featuredUser.name || "Profile"}
                    />
                  )}
                </div>
                <div className="ms-3">
                  <h4 style={{ textTransform: "capitalize" }} className="mb-0">
                    {featuredUser.name}
                  </h4>
                  <p className="mb-0">
                    {featuredUser?.gender?.charAt(0)?.toUpperCase() +
                      featuredUser?.gender?.slice(1)}
                  </p>
                </div>
              </div>
              <div>
                {featuredUser?.feature_profile !== "0" ? (
                  <img
                    src={featuredIcon}
                    className="featured-img"
                    alt="Featured"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="px-sm-3 pt-sm-3 pb-sm-4 p-2 d-flex align-items-center justify-content-between">
              <div className="pe-3 pe-xl-5">
                <p className="mb-1 mt-4 me-xl-5 ">
                  {featuredUser?.profession && `${featuredUser?.profession} | `}
                  {featuredUser?.native_city &&
                    `${featuredUser?.native_city} | `}
                  {featuredUser?.age && `${featuredUser?.age} Years | `}
                  {featuredUser?.marital_status &&
                    `${featuredUser?.marital_status} | `}
                  {featuredUser?.highest_qualification &&
                    `${featuredUser.highest_qualification}`}
                </p>
              </div>
              <div className="d-flex flex-column">
                <button
                  onClick={() => {
                    handleClick(featuredUser?.id);
                  }}
                  className="btn transparent-btn-theme"
                >
                  Message
                </button>
                <Link
                  to={`/visit-user-profile/${featuredUser?.id}`}
                  className="text-decoration-none text-dark"
                >
                  <button className="mt-3 btn text-nowrap clr-btn-theme">
                    View Profile
                  </button>
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default FeaturedProfiles;
